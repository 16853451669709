import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_API } from '../../constants'

const getHookEvaluation = createAsyncThunk('analysis/evaluation', async ({ videoUrl, videoId }) => {
  return axios.post(`${SERVER_API.BASE_URL}/hook-evaluate`, { video_url: videoUrl, video_id: videoId }, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true
  })
    .then(response => {
      return { data: response.data };
    })
    .catch(error => {
      if (error.response.data) {
        return { error: error.response.data };
      } else {
        return { error };
      }
    });
});

export { getHookEvaluation };
