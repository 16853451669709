import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { analysisReducer } from './slices/analysisSlice';

export const store = configureStore({
  reducer: {
    analysis: analysisReducer
  }
});

setupListeners(store.dispatch);

export * from './thunks/getTopSimilarAdVideos';
export * from './thunks/getCreativeAnalysis'
export * from './thunks/getScreenshotDescriptions';
export * from './thunks/getHookEvaluation';
