import MultilineText from "../../components/MultilineText";

function ScreenshotDescriptionTable({ descTable }) {
  const renderedRows = descTable.map((cells, index) => {
    return (
      <tr key={index}>
        <td>{cells[0].at(0)} sec ~ {cells[0].at(-1)} sec</td>
        <td><img src={cells[1]} alt="avatar" /></td>
        <td><MultilineText className="text-left">{cells[2]}</MultilineText></td>
      </tr>
    );
  });

  return (
    <div className="list">
      <table>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Image</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {renderedRows}
        </tbody>
      </table>
    </div>
  );
}

export default ScreenshotDescriptionTable;
