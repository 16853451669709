function MultilineText({ children, trimEmptyLines = false, ...rest }) {
  if (trimEmptyLines && children) {
    children = children.replace(/(^(\n)*)/, '').replace(/((\n)*$)/, '');
  }
  return (
    <span {...rest}>{children && children.split('\n').map((item, index) => item.trim() ? <p key={index}>{item}</p> : <p key={index}>&nbsp;</p>)}</span>
  );
}

export default MultilineText;
