import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Layout from './pages/Layout';
import AnalysisPage from './pages/analysisPage';

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<AnalysisPage />} />
          <Route path='*' element={<AnalysisPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
