import ReactMarkdown from 'react-markdown';
import {hookTactics} from './hookTactics';

const LinkRenderer = props => {
  return (
    <a className="underline decoration-[#444ce7]" href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
};

function HookTacticItem({ index }) {
  const hookTactic = hookTactics[index]
  if (hookTactic) {
    return (
      <div className="text-[#222222] mb-12">
        <p className="text-xl font-bold">{hookTactic['title']}</p>
        <div className="h-0.5 w-60 md:w-[30rem] bg-[#E4E7EB] my-4" />
        <p className="font-bold underline">How to use it:</p>
        <ReactMarkdown>{hookTactic['howToMarkdown']}</ReactMarkdown>
        <p className="font-bold underline my-6">Hook Tactic #{index + 1} in action:</p>
        <ReactMarkdown>{hookTactic['inActionMarkdown']}</ReactMarkdown>
        <iframe
          className="my-5 w-[16rem] h-[9rem] sm:w-[24rem] sm:h-[14rem] md:w-[32rem] md:h-[18rem] lg:w-[48rem] lg:h-[27rem]"
          src={`https://www.youtube.com/embed/${hookTactic['videoId']}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
        <ReactMarkdown components={{ a: LinkRenderer }} className="mb-8">{hookTactic['promoMarkdown']}</ReactMarkdown>
      </div>
    );
  } else {
    return <></>;
  }
}

export default HookTacticItem