module.exports.APP_STATUS = {
  // analysis component
  URL_INPUT: 0,
  SIMILAR_AD_VIDEOS_LOADING: 1,
  SIMILAR_AD_VIDEOS_SUCCESS: 2,
  SIMILAR_AD_VIDEOS_FAILED: 3,
  CREATIVE_ANALYSIS_LOADING: 4,
  CREATIVE_ANALYSIS_SUCCESS: 5,
  CREATIVE_ANALYSIS_FAILED: 6,
  SCREENSHOT_DESCRIPTIONS_LOADING: 7,
  SCREENSHOT_DESCRIPTIONS_SUCCESS: 8,
  SCREENSHOT_DESCRIPTIONS_FAILED: 9,
  HOOK_EVALUATION_LOADING: 10,
  HOOK_EVALUATION_SUCCESS: 11,
  HOOK_EVALUATION_FAILED: 12
};

module.exports.CONFIG = {
  THEME_COLOR: '#305BE8'
};

module.exports.SERVER_API = {
  BASE_URL: 'https://creativeanalysis.api.inceptly.com'
};
