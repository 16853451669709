import { createSlice } from '@reduxjs/toolkit';
import {getTopSimilarAdVideos, getCreativeAnalysis, getScreenshotDescriptions, getHookEvaluation} from '../index';
import { APP_STATUS } from '../../constants';

const defaultErrorMessage = 'Oops... Something went wrong. Please try again later.';

const analysisSlice = createSlice({
  name: 'analysis',
  initialState: {
    currentStatus: APP_STATUS.URL_INPUT,
    errorMessage: null,
    similarAdVideos: [[], []],
    creativeAnalysisTextItems: null,
    videoInfoItems: null,
    descTables: null,
    hookEvaluationResults: null
  },
  extraReducers(builder) {
    builder.addCase(getTopSimilarAdVideos.pending, (state, action) => {
      state.currentStatus = APP_STATUS.SIMILAR_AD_VIDEOS_LOADING;
      state.similarAdVideos = [[], []];
    });
    builder.addCase(getTopSimilarAdVideos.fulfilled, (state, action) => {
      const { data, error } = action.payload;
      if (data) {
        state.similarAdVideos = data;
        state.currentStatus = APP_STATUS.SIMILAR_AD_VIDEOS_SUCCESS;
      } else if (error) {
        state.errorMessage = error.error;
        state.currentStatus = APP_STATUS.SIMILAR_AD_VIDEOS_FAILED;
      } else {
        state.errorMessage = "No data in the response.";
        state.currentStatus = APP_STATUS.SIMILAR_AD_VIDEOS_FAILED;
      }
    });
    builder.addCase(getTopSimilarAdVideos.rejected, (state, action) => {
      state.errorMessage = defaultErrorMessage
      state.currentStatus = APP_STATUS.SIMILAR_AD_VIDEOS_FAILED;
    });

    builder.addCase(getCreativeAnalysis.pending, (state, action) => {
      state.currentStatus = APP_STATUS.CREATIVE_ANALYSIS_LOADING
      state.creativeAnalysisTextItems = null;
      state.videoInfoItems = null;
    });
    builder.addCase(getCreativeAnalysis.fulfilled, (state, action) => {
      const { data, error } = action.payload;
      if (data) {
        state.creativeAnalysisTextItems = data.creative_analysis_text;
        state.videoInfoItems = data.video_info;
        state.currentStatus = APP_STATUS.CREATIVE_ANALYSIS_SUCCESS;
      } else if (error) {
        state.errorMessage = error.error;
        state.currentStatus = APP_STATUS.CREATIVE_ANALYSIS_FAILED;
      } else {
        state.errorMessage = "No data in the response.";
        state.currentStatus = APP_STATUS.CREATIVE_ANALYSIS_FAILED;
      }
    });
    builder.addCase(getCreativeAnalysis.rejected, (state, action) => {
      state.errorMessage = defaultErrorMessage
      state.currentStatus = APP_STATUS.CREATIVE_ANALYSIS_FAILED;
    });

    builder.addCase(getScreenshotDescriptions.pending, (state, action) => {
      state.currentStatus = APP_STATUS.SCREENSHOT_DESCRIPTIONS_LOADING
      state.descTables = null;
    });
    builder.addCase(getScreenshotDescriptions.fulfilled, (state, action) => {
      const { data, error } = action.payload;
      if (data) {
        state.descTables = data;
        state.currentStatus = APP_STATUS.SCREENSHOT_DESCRIPTIONS_SUCCESS;
      } else if (error) {
        state.errorMessage = error.error;
        state.currentStatus = APP_STATUS.SCREENSHOT_DESCRIPTIONS_FAILED;
      } else {
        state.errorMessage = "No data in the response.";
        state.currentStatus = APP_STATUS.SCREENSHOT_DESCRIPTIONS_FAILED;
      }
    });
    builder.addCase(getScreenshotDescriptions.rejected, (state, action) => {
      state.errorMessage = defaultErrorMessage
      state.currentStatus = APP_STATUS.SCREENSHOT_DESCRIPTIONS_FAILED;
    });

    builder.addCase(getHookEvaluation.pending, (state, action) => {
      state.currentStatus = APP_STATUS.HOOK_EVALUATION_LOADING;
      state.hookEvaluationResults = null
    });
    builder.addCase(getHookEvaluation.fulfilled, (state, action) => {
      const { data, error } = action.payload;
      if (data) {
        state.hookEvaluationResults = data;
        state.currentStatus = APP_STATUS.HOOK_EVALUATION_SUCCESS;
      } else if (error) {
        state.errorMessage = error.error;
        state.currentStatus = APP_STATUS.HOOK_EVALUATION_FAILED;
      } else {
        state.errorMessage = "No data in the response.";
        state.currentStatus = APP_STATUS.HOOK_EVALUATION_FAILED;
      }
    });
    builder.addCase(getHookEvaluation.rejected, (state, action) => {
      state.errorMessage = defaultErrorMessage
      state.currentStatus = APP_STATUS.HOOK_EVALUATION_FAILED;
    });
  }
});

export const analysisReducer = analysisSlice.reducer;
